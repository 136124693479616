import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import Logo from "./img/baecom_logo.png";
import NewBadge from "./img/NEWbadge.png";

import bzzLogo from '../bzz/img/bzzLogo.png';
import oliyoKoreanLogo from '../oliyo/img/Oliyo_Korean_Cropped_Logo.png';

function TopNav() {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const isPc = useMediaQuery({ query: "(min-width:761px)" });

    const [isLogined, setIsLogined] = useState(false);
    const [isCsatBanner, setIsCsatBanner] = useState(true);

    const location = useLocation();
    const isBoard = location.pathname.substring(0,6) == '/board';
    const isBaecar = location.pathname == '/baecar';
    const isMyinfo = location.pathname == '/myinfo';
    const isReview = location.pathname.substring(0,7) == '/review';
    const isBzz = location.pathname.substring(0,4) == '/bzz';
    const isOliyo = location.pathname.substring(0,6) == '/oliyo';

    useEffect(() => {
        if(cookies.get("BAEUID")) setIsLogined(true);
        else setIsLogined(false);
        if(cookies.get("BAENOBANNER") == 1) setIsCsatBanner(false);
    });

    const onOliyoClick = () => {
        const authorization = cookies.get('authorization');
        axios.post(process.env.REACT_APP_API_SERVER + '/oliyo/user/profile', {uid: cookies.get("BAEUID")}, {
            headers: {
              authorization: authorization,
            }
        }).then((res) => {
            navigate('/oliyo/main');
        })
        .catch((err) => {
            navigate('oliyo/promotion');
        });
    }

    const logoutHandler = () => {
        cookies.remove("BAEUID", {path:'/'});
        cookies.remove("BAEALEVEL", {path:'/'});
        cookies.remove("authorization", {path:'/'});
        cookies.remove("BAEID", {path: '/'});
        cookies.remove("BAENOBANNER", {path: '/'})
        alert("로그아웃 되었습니다.");
        setIsLogined(false);
        if(location.pathname == '/myinfo' || location.pathname == '/baecar') navigate('/');
        window.location.reload();
    }
    const today = new Date();
    const targetDate = new Date('2024-11-14');
    const mockDate = new Date('2024-10-02');

    // 밀리초 단위 차이 계산
    const diffTime = targetDate - today;

    // 밀리초를 일수로 변환
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    //{isCsatBanner?<SAdText onClick={() => navigate("/baecar/csatexam")}>😀 수능 성적표 인증하고 배카 완성시키자!</SAdText>:""}
    // <SMainDiv className="banner">
    /*{mockDate-today>0?<SAdText onClick={() => navigate("/chingho")}>수능 D-{diffDays} 😀 칭호는 여기로 😀</SAdText>:
            <SAdText onClick={() => navigate("/mockexam")}>수능 D-{diffDays} 😀 9평 인증하기 😀</SAdText>}*/
    //<SAdText onClick={() => navigate("/chingho")}>수능 D-{diffDays} 😀 배커가 응원합니다 😀</SAdText>
    return (
        <SMainDiv className="banner">
            <SAdText onClick={() => navigate("/oliyo/promotion")}>맞춤 학습 관리 🫒올리요🐰</SAdText>
            <SNavBar>
                <a href="/" aria-label="배커 홈으로 이동"><SLogoImg src={Logo} alt="배커 아이콘" /></a>
                {isPc?
                <>
                    <SNavBtn className={ isBoard ? "highlighted" : "" } href="/board">게시판</SNavBtn>
                    <SNavBtn className={ isReview ? "highlighted" : "" } href="/review">학습후기</SNavBtn>
                </>:""}
                <SNavBtn className={ isBaecar ? "highlighted" : "" } href="/baecar">배카</SNavBtn>
                <SBzzLogo className={ isBzz ? "highlighted" : "" } href="/bzz" />
                <SOliyoLogo className={ isOliyo ? "highlighted" : "" } onClick={onOliyoClick} />
                {isLogined?(!isPc && isMyinfo?<SLoginBtn onClick={logoutHandler}>로그아웃</SLoginBtn>:
                <SLoginBtn onClick={() => navigate("/myinfo")}>내 정보</SLoginBtn>):
                <SLoginBtn onClick={() => navigate("/login", {state: {prev: location.pathname}})}>로그인</SLoginBtn>}
                {isLogined?<SJoinBtn onClick={logoutHandler}>로그아웃</SJoinBtn>:
                <SJoinBtn onClick={() => navigate("/join", {state: {prev: location.pathname}})}>회원가입</SJoinBtn>}
            </SNavBar>
            
        </SMainDiv>
    );
}

//original height: 48px
//original mobile height: 50px
//original top padding: 33px;

const SMainDiv = styled.div`
    height: 48px;
    width: 100vw;
    text-align: center;
    padding-top: 0px;

    @media screen and (max-width: 760px) {
        height: 50px;
    }

    a {
        text-decoration: none;
    }

    &.banner {
        height: 110px;
        padding-top: 0px;
        @media screen and (max-width: 760px) {
            height: 112px;
        }
    }
`;

const SNavBar = styled.div`
    display: flex;
    height: 48px;
    width: 100vw;
    max-width: 1222px;
    margin: 20px auto 0px;
    align-items: center;

    @media screen and (max-width: 1220px) {
        max-width: 920px;
    }

    @media screen and (max-width: 760px) {
        margin-top: 10px;
        height: 50px;
    }
`;

const SAdText = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    font-weight: 700;
    color: #F5F5F5;
    background: #222222;
    z-index: 10;
`;

const SLogoImg = styled.img`
    width: 259px;
    height: 48px;
    margin-left: 0px;
    margin-right: 55px;

    &:hover {
        cursor: pointer;
    }

    @media screen and (max-width: 1220px) {
        margin-right: 15px;
    }

    @media screen and (max-width: 940px) {
        margin-right: 5px;
    }

    @media screen and (max-width: 760px) {
        width: 172px;
        height: 32px;
        margin-left: 10px;
        margin-right: 0px;
    }
`;

const SNavBtn = styled.a`
    display: flex;
    margin: 0px 15px 0px 15px;
    align-items: center;

    color: #000000;
    font-size: 20px;
    font-weight: 500;

    cursor: pointer;

    &:hover {
        font-weight: 900;
    }

    &.highlighted {
        font-weight: 900;
    }

    @media screen and (max-width: 1220px) {
        font-size: 15px;
    }

    @media screen and (max-width: 860px) {
        margin: auto 5px auto auto;
    }

    @media screen and (max-width: 760px) {
        margin: auto 5px auto auto;
        font-size: 15px;
    }

    @media screen and (max-width: 450px) {
        display: none;
    }
`;

const SBadge = styled.div`
    background-image: url(${NewBadge});
    background-size: contain;

    position: relative;
    top: -13px;
    left: 3px;

    width: 32px;
    height: 16px;

    @media screen and (max-width: 760px) {
        width: 24px;
        height: 12px;

        top: -14px;
        left: -2px;
    }
`;

const SBzzLogo = styled.a`
    width: 70px;
    height: 20.5px;
    filter: grayscale(1);
    margin: 3px 15px 0px 15px;
    
    background-image: url(${bzzLogo});
    background-size: contain;
    background-repeat: no-repeat;

    &.highlighted {
        filter: grayscale(0);
    }
    &:hover {
        filter: grayscale(0);
    }
    @media screen and (max-width: 940px) {
        margin-left: 5px;
        margin-right: 5px;
    }
    @media screen and (max-width: 510px) {
        display: none;
    }
`;

const SOliyoLogo = styled.a`
    width: 100px;
    height: 30px;
    filter: grayscale(1);
    margin: 0px 15px 7px 10px;
    
    background-image: url(${oliyoKoreanLogo});
    background-size: contain;
    background-repeat: no-repeat;

    cursor: pointer;

    &.highlighted {
        filter: grayscale(0);
    }
    &:hover {
        filter: grayscale(0);
    }
    @media screen and (max-width: 940px) {
        margin-left: 5px;
        margin-right: 5px;
    }
    @media screen and (max-width: 450px) {
        margin-left: auto;
        width: 70px;
    }
`;

const SLoginBtn = styled.div`
    display: flex;
    height: 40px;
    width: 80px;
    margin-left: auto;
    margin-right: 10px;

    align-items: center;
    justify-content: center;

    background: #CB6445;

    color: #FFFFFF;
    font-size: 15px;
    font-weight: 900;

    cursor: pointer;

    &:hover {
        background : #DB937D;
    }

    @media screen and (max-width: 760px) {
        height: 30px;
        margin-left: 10px;
        font-size: 15px;
    }

    @media screen and (max-width: 360px) {
        margin-left: auto;
    }
`;

const SJoinBtn = styled.div`
    display: flex;
    height: 40px;
    width: 80px;
    margin-left: 10px;
    margin-right: 0px;

    align-items: center;
    justify-content: center;

    background: #FFFFFF;

    color: #000000;
    font-size: 15px;
    font-weight: 900;

    border: 1px solid black;

    cursor: pointer;

    &:hover {
        background : #EBEBEB;
    }

    @media screen and (max-width: 940px) {
        margin-right: 10px;
    }

    @media screen and (max-width: 760px) {
        display: none;
    }
`;

export default TopNav;