import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import TopNav from './components/common/TopNav.js';
import NotFound from './components/common/NotFound.js';

import './App.css';

const Home = lazy(() => import('./pages/Home.js'));
const Login = lazy(() => import('./pages/Login.js'));
const Join = lazy(() => import('./pages/Join.js'));
const UserInfo = lazy(() => import('./pages/UserInfo.js'));
const Chingho = lazy(() => import('./pages/Chingho.js'));
const Withdraw = lazy(() => import('./pages/Withdraw.js'));
const EmailAuth = lazy(() => import('./pages/EmailAuth.js'));
const PasswordFind = lazy(() => import('./pages/PasswordFind.js'));
const WritePost = lazy(() => import('./pages/WritePost.js'));
const Post = lazy(() => import('./pages/Post.js'));
const EditPost = lazy(() => import('./pages/EditPost.js'));
const Board = lazy(() => import('./pages/Board.js'));
const ReviewPost = lazy(() => import('./pages/ReviewPost.js'));
const Review = lazy(() => import('./pages/Review.js'));
const Baecar = lazy(() => import('./pages/Baecar.js'));
const BaecarMerge = lazy(() => import('./pages/BaecarMerge.js'));
const BaecarUpgrade = lazy(() => import('./pages/BaecarUpgrade.js'));
//const MockExam = lazy(() => import('./pages/MockExam.js'));
const AdminHome = lazy(() => import('./pages/AdminHome.js'));
const Admin9Mock = lazy(() => import('./pages/Admin9Mock.js'));
//const CsatExam = lazy(() => import('./pages/CsatExam.js'));
const AdminCsat = lazy(() => import('./pages/AdminCsat.js'));
const AdminChingho = lazy(() => import('./pages/AdminChingho.js'));
const Bzz = lazy(() => import('./pages/Bzz.js'));
const BzzPost = lazy(() => import('./pages/BzzPost.js'));
const Admin6Mock = lazy(() => import('./pages/Admin6Mock.js'));
const PotLogin = lazy(() => import('./pages/PotLogin.js'));
const PotConnectLogin = lazy(() => import('./pages/PotConnectLogin.js'));
const OliyoPromo = lazy(() => import('./pages/OliyoPromo.js'));
const OliyoPromoGA = lazy(() => import('./pages/OliyoPromoGA.js'));
const OliyoPremiumCodePage = lazy(() => import('./pages/OliyoPremiumCodePage.js'));
const AdminOliyoAddPage = lazy(() => import('./pages/AdminOliyoAddPage.js'));
const AdminOliyoSsenAddPage = lazy(() => import('./pages/AdminOliyoSsenAddPage.js'));
const OliyoInbody = lazy(() => import('./components/oliyo/OliyoInbody.js'));
const OliyoMain = lazy(() => import('./components/oliyo/OliyoMain.js'));
const OliyoMyPage = lazy(() => import('./components/oliyo/OliyoMyPage.js'));
const OliyoNoteList = lazy(() => import('./components/oliyo/OliyoNoteList.js'));
const OliyoNoteSolve = lazy(() => import('./components/oliyo/OliyoNoteSolve.js'));
const OliyoQnaCreate = lazy(() => import('./components/oliyo/OliyoQnaCreate.js'));
const OliyoQnaStatus = lazy(() => import('./components/oliyo/OliyoQnaStatus.js'));
const OliyoQnaDetail = lazy(() => import('./components/oliyo/OliyoQnaDetail.js'));
const OliyoSessionForm = lazy(() => import('./components/oliyo/OliyoSessionForm.js'));
const OliyoSessionStatus = lazy(() => import('./components/oliyo/OliyoSessionStatus.js'));
const OliyoSolve = lazy(() => import('./components/oliyo/OliyoSolve.js'));
const OliyoSolveMain = lazy(() => import('./components/oliyo/OliyoSolveMain.js'));
const OliyoSolveOMR = lazy(() => import('./components/oliyo/OliyoSolveOMR.js'));
const OliyoMainTA = lazy(() => import('./components/oliyo/OliyoMainTA.js'));
const OliyoChatTA = lazy(() => import('./components/oliyo/OliyoChatTA.js'));
const OliyoMyPageTA = lazy(() => import('./components/oliyo/OliyoMyPageTA.js'));
const OliyoQnaStatusTA = lazy(() => import('./components/oliyo/OliyoQnaStatusTA.js'));
const OliyoQnaDetailTA = lazy(() => import('./components/oliyo/OliyoQnaDetailTA.js'));

function App() {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <div className="App">
          <TopNav />
          <Suspense>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/join" element={<Join />} />
              <Route path="/myinfo" element={<UserInfo />} />
              <Route path="/chingho" element={<Chingho />} />
              <Route path="/withdraw" element={<Withdraw />} />
              <Route path="/emailauth" element={<EmailAuth />} />
              <Route path="/findpassword" element={<PasswordFind />} />
              
              <Route path="/board/write" element={<WritePost/>} />
              <Route path="/board/:pid" element={<Post />} />
              <Route path="/board/edit/:pid" element={<EditPost/>} />
              <Route path="/board" element={<Board />} />
              <Route path="/review/:pid" element={<ReviewPost />} />
              <Route path="/review" element={<Review />} />

              <Route path="/baecar/2024" element={<Baecar season="2024"/>} />
              <Route path="/baecar" element={<Baecar />} />
              <Route path="/baecar/2024/merge" element={<BaecarMerge season="2024"/>} />
              <Route path="/baecar/merge" element={<BaecarMerge />} />
              <Route path="/baecar/upgrade" element={<BaecarUpgrade />} />

              <Route path="/bzz" element={<Bzz />} />
              <Route path="/bzz/:videoId" element={<BzzPost />} />

              <Route path="/main" element={<Navigate replace to="/" />} />

              <Route path="/admin" element={<AdminHome />} />
              <Route path="/admin/9mock" element={<Admin9Mock />} />
              <Route path="/admin/9mock/:uid" element={<Admin9Mock />} />
              <Route path="/admin/6mock" element={<Admin6Mock />} />
              <Route path="/admin/6mock/:uid" element={<Admin6Mock />} />
              <Route path="/admin/csat" element={<AdminCsat />} />
              <Route path="/admin/csat/:uid" element={<AdminCsat />} />
              <Route path="/admin/chingho" element={<AdminChingho />} />
              <Route path="/admin/chingho/:id" element={<AdminChingho />} />
              <Route path="/admin/oliyo/add" element={<AdminOliyoAddPage />} />
              <Route path="/admin/oliyo/ssenadd" element={<AdminOliyoSsenAddPage />} />

              <Route path="/oliyo/promotion" element={<OliyoPromo />} />
              <Route path="/oliyo/ga_promotion" element={<OliyoPromoGA />} />
              <Route path="/oliyo/premium" element={<OliyoPremiumCodePage />} />
              
              <Route path="/oliyo/main" element={<OliyoMain />} />
              <Route path="/oliyo/solve" element={<OliyoSolveMain />} />
              <Route path="/oliyo/solve/omr" element={<OliyoSolveOMR />} />
              <Route path="/oliyo/session" element={<OliyoSessionStatus />} />
              <Route path="/oliyo/session/create" element={<OliyoSessionForm />} />
              <Route path="/oliyo/qna" element={<OliyoQnaStatus />} />
              <Route path="/oliyo/qna/create" element={<OliyoQnaCreate />} />
              <Route path="/oliyo/qna/:qnaid" element={<OliyoQnaDetail />} />
              <Route path="/oliyo/note" element={<OliyoNoteList />} />
              <Route path="/oliyo/note/:note_id" element={<OliyoNoteSolve />} />
              <Route path="/oliyo/mypage" element={<OliyoMyPage />} />
              <Route path="/oliyo/inbody" element={<OliyoInbody />} />

              <Route path="/oliyo/admin" element={<OliyoMainTA />} />
              <Route path="/oliyo/admin/chat" element={<OliyoChatTA />} />
              <Route path="/oliyo/admin/mypage" element={<OliyoMyPageTA />} />
              <Route path="/oliyo/admin/qna" element={<OliyoQnaStatusTA />} />
              <Route path="/oliyo/admin/qna/:qnaid" element={<OliyoQnaDetailTA />} />

              <Route path="/potential/login" element={<PotLogin />} />
              <Route path="/connectlogin" element={<PotConnectLogin />} />
              <Route path="/notfound" element={<NotFound />} />
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </Suspense>
          <footer>
          </footer>
        </div>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;